import { AsyncPipe, I18nPluralPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  forwardRef,
  inject,
  input,
  Output,
  signal,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT, TIME_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { ButtonComponent, InputFieldComponent } from '../../atoms';
import { TagLabelComponent } from '../../atoms/tag-label/tag-label.component';
import { InputWrapperSignal } from '../../core/input-wrapper';
import { CompactDatePipe } from '../../pipes/date/compact-date.pipe';
import { DatePipe } from '../../pipes/date/date.pipe';
import { CalendarComponent } from '../calendar/calendar.component';
import { DropdownGroupingSelectComponent } from '../dropdown-grouping-select/dropdown-grouping-select.component';
import { SlidePanelComponent } from '../slide-panel/locumsnest-slide-panel';
import { CustomAdapter } from './calendar-adapter.service';
import { optionStrategies } from './calendar-event-strategies';

@Component({
  selector: 'locumsnest-compact-event-calendar',
  templateUrl: './compact-event-calendar.component.html',
  styleUrls: ['./compact-event-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CalendarComponent,
    TagLabelComponent,
    AsyncPipe,
    CompactDatePipe,
    DatePipe,
    I18nPluralPipe,
    NgbDatepickerModule,
    FormsModule,
    InputFieldComponent,
    DropdownGroupingSelectComponent,
    ButtonComponent,
    SlidePanelComponent,
  ],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompactEventCalendarComponent),
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
  ],
})
export class CompactEventCalendarComponent
  extends InputWrapperSignal<string[]>
  implements AfterViewInit
{
  readonly #yearDays = 365;
  shiftLabel = { '=0': '-- shifts', '=1': '# shift', other: '# shifts' };
  canSelectPastDate = input(false);
  maxAllowSelections = input<number>();
  referenceStartTime = input<Date>();
  referenceEndTime = input<Date>();
  dropdownDuration = signal(optionStrategies['days'].selectOption.id);
  hoveredDate: NgbDate | null = null;
  fromDate: string | null = null;
  toDate: string | null = null;
  selectOptions = Object.values(optionStrategies).map((val) => val.selectOption);
  errorMsg = signal('');
  bankHolidays = input<string[]>([]);
  generated = signal(false);
  tempValue = signal<string[]>([]);
  extraDays = signal<string[]>([]);
  alignCenter = input(false);

  @Output() closeCloningSection = new EventEmitter<void>();

  ngbCalendar = inject(NgbCalendar);
  private cd = inject(ChangeDetectorRef);
  dateAdapter: NgbDateAdapter<string> = inject(NgbDateAdapter);

  ngAfterViewInit() {
    if (this.value().length) {
      this.fromDate = this.value()[0];
      this.toDate = this.value()[this.value().length - 1];
      this.generated.set(true);
    }
  }

  minDate = computed(() => (this.canSelectPastDate() ? null : this.getToday()));

  eventList = computed(() => {
    if (this.generated()) {
      return this.value().map((date) => ({
        display:
          `${date}` +
          ` | ` +
          `${Time.formatDate(this.referenceStartTime(), TIME_FORMAT)}` +
          ` to ` +
          `${Time.formatDate(this.referenceEndTime(), TIME_FORMAT)}`,
        date,
      }));
    }
    return [];
  });

  referenceStartDate = computed(() => Time.formatDate(this.referenceStartTime()));

  eventCount = computed(() => this.value().length + this.extraDays().length);

  countDates = computed(() =>
    [...this.value(), ...this.extraDays()].reduce(
      (acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc),
      {},
    ),
  );

  disableGenerateButton = computed(
    // +1 to show the warning but be able to generate(when selecting one by one date after range)
    () => !this.value().length || this.value().length > this.maxAllowSelections() + 1,
  );

  removeDate(index: number): void {
    this.value().splice(index, 1);
    this.onChange(this.value());
  }

  fromDateChange(event: Event) {
    const { value } = event.target as HTMLInputElement;
    if (Time.isValidMoment(value, DATE_FORMAT)) {
      this.setFromToDate(value, this.toDate);
    }
  }

  toDateChange(event: Event) {
    const { value } = event.target as HTMLInputElement;
    if (Time.isValidMoment(value, DATE_FORMAT) && this.fromDate) {
      this.setFromToDate(this.fromDate, value);
    }
  }

  getToday() {
    return this.ngbCalendar.getToday();
  }

  onDateSelection(date: NgbDate) {
    if (this.ngbCalendar.isValid(date)) {
      this.resetError();
      this.onTouched();
      const toModelDate = this.dateAdapter.toModel(date);

      if (this.fromDate && this.toDate) {
        if (this.eventCount() + this.tempValue().length + 1 > this.maxAllowSelections()) {
          this.setError(
            `The maximum number of allowed selections is ${this.maxAllowSelections()}.`,
          );
          return;
        }
        return this.extraDays.update((days) => [...days, toModelDate]);
      }

      if (!this.canSelectPastDate() && date.before(this.getToday())) {
        this.setError('Date cannot be in the past.');
        return;
      }

      if (!this.fromDate && !this.toDate) {
        this.fromDate = toModelDate;
      } else if (
        this.fromDate &&
        !this.toDate &&
        (date.after(this.dateAdapter.fromModel(this.fromDate)) ||
          date.equals(this.dateAdapter.fromModel(this.fromDate)))
      ) {
        this.toDate = toModelDate;

        const fromDate = Time.getMoment(this.fromDate, DATE_FORMAT);
        const toDate = Time.getMoment(this.toDate, DATE_FORMAT);
        const diff = toDate.diff(fromDate, 'days');
        if (diff + this.tempValue().length > this.#yearDays) {
          this.setError('Selection range cannot span over one year.');
          return;
        }

        const range: string[] = [];

        for (let i = 0; i <= diff; i++) {
          const day = Time.getMoment(fromDate).add(i, 'days');
          if (this.checkSelection(day.day())) range.push(day.format(DATE_FORMAT));
        }

        if (range.length + this.tempValue().length > this.maxAllowSelections()) {
          this.setError(
            `The maximum number of allowed selections is ${this.maxAllowSelections()}.`,
          );
          this.fromDate = null;
          this.toDate = null;
        } else {
          this.fromDate = range[0];
          this.toDate = range[range.length - 1];
          this.writeValue(range);
        }
      } else {
        this.toDate = null;
        this.fromDate = toModelDate;
        this.writeValue([]);
      }
    }
  }

  selectChange(event: number) {
    this.dropdownDuration.set(event);
    this.setFromToDate(this.fromDate, this.toDate);
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.dateAdapter.fromModel(this.fromDate)) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.value().includes(this.dateAdapter.toModel(date)) &&
      this.toDate &&
      date.after(this.dateAdapter.fromModel(this.fromDate)) &&
      date.before(this.dateAdapter.fromModel(this.toDate))
    );
  }

  isRange(date: NgbDate) {
    if (this.ngbCalendar.isValid(date)) {
      return (
        date.equals(this.dateAdapter.fromModel(this.fromDate)) ||
        (this.toDate && date.equals(this.dateAdapter.fromModel(this.toDate))) ||
        this.isInside(date) ||
        this.isHovered(date)
      );
    }
  }
  isSelected(date: NgbDate) {
    if (this.ngbCalendar.isValid(date)) {
      return this.extraDays().includes(this.dateAdapter.toModel(date));
    }
  }

  isReferenceDate(date: NgbDate) {
    const fromDate = Time.formatDate(this.referenceStartTime());
    if (this.ngbCalendar.isValid(date)) {
      return date.equals(this.dateAdapter.fromModel(fromDate));
    }
  }

  generate(generate: boolean) {
    const newValue = [...this.value(), ...this.tempValue(), ...this.extraDays()];
    this.tempValue.set([]);
    this.extraDays.set([]);
    this.writeValue(newValue);
    this.onChange(newValue);
    this.generated.set(generate);
  }

  resetDatesAndRange() {
    this.tempValue.set([]);
    this.writeValue([]);
    this.onChange(this.value());
    this.generated.set(false);
    this.fromDate = null;
    this.toDate = null;
    this.cd.markForCheck();
    this.extraDays.set([]);
  }

  isBankHoliday(date: NgbDate) {
    return this.bankHolidays().includes(this.dateAdapter.toModel(date));
  }

  addNew() {
    const currentValue = [...this.value()];
    this.resetDatesAndRange();
    this.tempValue.set(currentValue);
  }

  setError(msg: string) {
    this.errorMsg.set(msg);
  }

  resetError() {
    this.errorMsg.set('');
  }

  onCloseCloningSection() {
    this.closeCloningSection.emit();
  }

  private setFromToDate(fromDate: string | null = null, toDate: string | null = null) {
    this.extraDays.update((days) =>
      days.filter((day) => this.checkSelection(Time.getMoment(day, DATE_FORMAT).day())),
    );

    this.fromDate = null;
    this.toDate = null;

    this.onDateSelection(this.getNgbDateFromModel(fromDate));
    this.onDateSelection(this.getNgbDateFromModel(toDate));
  }

  private getNgbDateFromModel(date: string | null) {
    return NgbDate.from(this.dateAdapter.fromModel(date));
  }

  private selectionCode = computed(
    () => this.selectOptions.find((option) => option.id === this.dropdownDuration()).code,
  );

  private checkSelection(day: number) {
    return optionStrategies[this.selectionCode()].checkDay(day);
  }
}
