<div class="notes-area">
  <div class="col-sm-9 notes-header">
    <div class="notes-title">{{ notesTitle }}</div>
    @if (!!checkboxFormControl) {
      <locumsnest-check-box
        displayText="Notify creator"
        [ngrxFormControlState]="checkboxFormControl"
      />
    }
  </div>
  <div class="row">
    <div class="col-sm-9">
      <locumsnest-text-area
        [ngrxFormControlState]="notesFormControl"
        [errorMsg]="getErrorMsg(notesFormControl)"
        [invertColors]="true"
        [isInvalid]="!notesFormControl.isDirty || notesFormControl.isInvalid"
        [rows]="rows"
        [cols]="14"
        [columnSize]="49"
        [placeholder]="placeholder"
      />
    </div>

    @if (showDefaultButton) {
      <div class="col-sm-3 notes-button">
        <locumsnest-button
          buttonType="go"
          size="medium"
          [disabled]="notesFormControl.isInvalid"
          (click)="onAddNote()"
        >
          Add note
        </locumsnest-button>
      </div>
    }
  </div>
</div>
