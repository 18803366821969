<div [ngClass]="{ 'horizontal-alignment': horizontalAlignment }">
  <div class="input-field" [ngClass]="{ 'date-width': horizontalAlignment }">
    @if (dateTitle.length) {
      <div class="title">{{ dateTitle }}</div>
    }
    <locumsnest-date-picker
      [disabled]="isDisabled"
      [placeholder]="datePlaceholder"
      [hasBorder]="hasBorder"
      [showCalendarAsPrefix]="showCalendarAsPrefix"
      (change)="onDateChange($event)"
      [(ngModel)]="viewDate"
    />
  </div>
  <div class="input-field" [ngClass]="{ 'time-width': horizontalAlignment }">
    @if (timeTitle.length) {
      <div class="title">{{ timeTitle }}</div>
    }
    <locumsnest-time-field
      [disabled]="isDisabled"
      [placeholder]="timePlaceholder"
      (timeChange)="onTimeChange($event)"
      [(ngModel)]="time"
    />
  </div>
</div>
