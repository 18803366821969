<locumsnest-check-box
  [idx]="idx"
  [displayText]="label"
  [isDisabled]="disabled"
  (checked)="onChecked($event)"
  [(ngModel)]="value"
/>
@if (value) {
  <locumsnest-widget-area [isInvalid]="isInvalid">
    <ng-content />
  </locumsnest-widget-area>
}
