<div class="container-badge">
  <div class="title-badge" [ngClass]="{ error: isInvalid, warning: isWarned }">
    {{ title }}
  </div>

  <div class="number-badge" [id]="idx" [ngClass]="{ error: isInvalid, warning: isWarned }">
    @if (displayValue > 0) {
      {{ displayValue | textDurationFromSeconds }}
    } @else {
      - - h - - m
    }
  </div>
</div>
