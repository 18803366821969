import { Component, EventEmitter, forwardRef, input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWrapperWithChangeDetection } from '@locumsnest/components/src/lib/core/input-wrapper';

import { IconComponent } from '../icon/icon.component';

type Theme = 'green' | 'blue';

@Component({
  selector: 'locumsnest-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  standalone: true,
  imports: [FormsModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true,
    },
  ],
  host: {
    '[class.invert-colors]': 'invertColors()',
    '[class.green-theme]': `theme() === 'green'`,
    '[class.blue-theme]': `theme() === 'blue'`,
    '[class.invalid]': `isInvalid()`,
    '[class.disabled]': `isDisabled()`,
    '[class.keep-colors-on-disable]': `keepColorsOnDisable()`,
  },
})
export class CheckBoxComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  idx = input('');
  displayText = input('');
  isInvalid = input(false);
  selected = input(false);
  isDisabled = input(false);
  theme = input<Theme>('blue');
  errorMsg = input('');
  iconName = input('x-new');
  invertColors = input(true);
  keepColorsOnDisable = input(false);

  @Output() checked = new EventEmitter<Event>();

  onChecked(selected: Event) {
    this.checked.emit(selected);
  }
}
