import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaskitoDirective } from '@maskito/angular';
import type { MaskitoOptions } from '@maskito/core';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DateTime } from '@locumsnest/core/src/lib/helpers/date-time';

import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';
import { IconComponent } from '../icon/icon.component';
import { InputFieldComponent } from '../input-field/input-field.component';
import mask from './time-field.mask';

@Component({
  selector: 'locumsnest-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss'],
  standalone: true,
  imports: [FormsModule, MaskitoDirective, InputFieldComponent, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    },
  ],
})
export class TimeFieldComponent extends InputWrapperWithChangeDetection {
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Output() timeChange = new EventEmitter<Event>();

  readonly options: MaskitoOptions = mask;

  writeTime(time: string) {
    if (time) {
      this.writeValue(time);
    }
  }

  get time() {
    return DateTime.getTimeFromPartialISOString(this.value);
  }

  set time(time: string) {
    const newTime = Time.getMoment(time, 'hh:mm');

    if (time?.length > 4 && newTime.isValid()) {
      this.writeTime(newTime.format('HH:mm'));
    } else {
      this.writeTime(time);
    }
  }

  public onTimeChange(event: Event) {
    this.timeChange.emit(event);
  }
}
