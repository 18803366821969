<div
  class="parent"
  [@slide]="{
    value:activePane, 
    params: {
      duration: this.duration
    }
  }"
>
  <div [class.align-center]="alignCenter"><ng-content select="[leftPane]" /></div>
  <div><ng-content select="[rightPane]" /></div>
</div>
