<locumsnest-input-field
  hasBorder
  [disabled]="disabled"
  [maskito]="options"
  [hasPrefix]="true"
  [placeholder]="placeholder"
  (change)="onTimeChange($event)"
  [(ngModel)]="value"
>
  <locumsnest-icon prefix iconName="time" width="14" height="14" />
</locumsnest-input-field>
