<ng-template>
  <div @parent>
    @if (active) {
      <div
        class="card-wizard-step"
        @enter
        [id]="stepNumber"
        [class.card-wizard-step__has-middle]="hasMiddle()"
        (@enter.done)="onAnimationEvent($event)"
      >
        @if (showFullCard) {
          <div class="main" [class.has-buttons]="showButton">
            @if (showLeftIcon) {
              <div class="icon-column">
                <ng-content select="[left-icon]" />
              </div>
            }
            <ng-content select="[main]" />
            @if (showButton) {
              <div class="full-button-container">
                @if (showCancelButton) {
                  <locumsnest-button
                    [buttonType]="'confirm'"
                    [hasBoldFont]="true"
                    [isBordered]="false"
                    [size]="'medium'"
                    (click)="onCancelButtonClick()"
                  >
                    {{ backButtonText }}
                  </locumsnest-button>
                }
                <div
                  tabindex="{{
                    isCurrentSectionInvalid || disableNextButton ? nextButtonTabIndex : -1
                  }}"
                  (focusout)="onNextFocus()"
                  (mousedown)="onNextMouseDown()"
                  (mouseover)="onNextMouseOver()"
                >
                  <locumsnest-button
                    tabindex="{{
                      isCurrentSectionInvalid || disableNextButton ? -1 : nextButtonTabIndex
                    }}"
                    [buttonType]="'onboarding-next'"
                    [hasBoldFont]="true"
                    [size]="'medium'"
                    [disabled]="isCurrentSectionInvalid || disableNextButton"
                    (click)="onGoToNextCard('')"
                  >
                    {{ buttonText }}
                  </locumsnest-button>
                </div>
                <ng-content select="[extraButtons]" />
              </div>
            }
          </div>
        }
        <div
          class="card-wizard-step__left-part"
          [class.card-wizard-step__left-part__has-middle]="hasMiddle()"
        >
          <div
            class="card-wizard-step__left-part__content"
            [ngClass]="{ 'card-wizard-step__left-part__content__header': headerTitle !== '' }"
          >
            @if (headerTitle !== '') {
              <label class="title"> {{ headerTitle }} </label>
            }
            @if (showLeftTitle) {
              <div class="row">
                <div class="col-sm-12">
                  @if (showStepNumber) {
                    <label class="title title-number"> {{ stepNumber }}. </label>
                  }
                  <label class="title">{{ leftTitle }}</label>
                </div>
              </div>
            }
            <div class="middle">
              <ng-content select="[left]" />
              <ng-content select="[warnings]" />
            </div>
            @if (showButton) {
              <div class="button-container">
                @if (showCancelButton) {
                  <locumsnest-button
                    [buttonType]="'confirm'"
                    [hasBoldFont]="true"
                    [isBordered]="false"
                    [size]="'medium'"
                    (click)="onCancelButtonClick()"
                  >
                    {{ backButtonText }}
                  </locumsnest-button>
                }
                <div
                  tabindex="{{
                    isCurrentSectionInvalid || disableNextButton ? nextButtonTabIndex : -1
                  }}"
                  (focusout)="onNextFocus()"
                  (mousedown)="onNextMouseDown()"
                  (mouseover)="onNextMouseOver()"
                >
                  <locumsnest-button
                    tabindex="{{
                      isCurrentSectionInvalid || disableNextButton ? -1 : nextButtonTabIndex
                    }}"
                    [buttonType]="'onboarding-next'"
                    [hasBoldFont]="true"
                    [size]="'medium'"
                    [disabled]="isCurrentSectionInvalid || disableNextButton"
                    (click)="onGoToNextCard('')"
                  >
                    {{ buttonText }}
                  </locumsnest-button>
                </div>
                <ng-content select="[extraButtons]" />
              </div>
            }
          </div>
        </div>
        @if (hasMiddle()) {
          <div class="card-wizard-step__middle">
            <ng-content select="[middle]" />
          </div>
        }

        <div
          class="card-wizard-step__right-part"
          [class.card-wizard-step__right-part__has-middle]="hasMiddle()"
        >
          @if (showRightTitle) {
            <div>
              <div class="card-wizard-step__right-part__title">
                {{ rightTitle }}
              </div>
            </div>
          }
          <div class="card-wizard-step__right-part__body scroller">
            <ng-content select="[right]" />
          </div>
        </div>
      </div>
    } @else {
      @if (completed) {
        <div class="completed" (click)="onCardClick(stepNumber)">
          <ng-content select="[completedCard]" />
        </div>
      } @else {
        <ng-content select="[inCompletedCard]" />
      }
      @if (showDeleteButton && completed === true) {
        <div class="bin" (click)="onRemoveQuestionButtonClicked(cardId)"></div>
      }
    }
  </div>
</ng-template>
