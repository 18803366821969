<div class="inputs-container" [class.align-center]="alignCenter()">
  <span class="inputs-container__text">Repeat this booking from</span>
  <locumsnest-input-field
    class="inputs-container__date"
    placeholder="dd/mm/yyyy"
    [hasBorder]="true"
    [showCalendarIconInInput]="true"
    [value]="fromDate || null"
    [disabled]="generated()"
    (change)="fromDateChange($event)"
  />
  <span class="inputs-container__text">to</span>
  <locumsnest-input-field
    class="inputs-container__date"
    placeholder="dd/mm/yyyy"
    [hasBorder]="true"
    [showCalendarIconInInput]="true"
    [value]="toDate || null"
    [disabled]="generated()"
    (change)="toDateChange($event)"
  />

  <span class="inputs-container__text">on</span>
  <locumsnest-dropdown-grouping-select
    class="inputs-container__duration"
    [searchable]="false"
    [ngModel]="dropdownDuration()"
    [options]="selectOptions"
    [isDisabled]="generated()"
    (select)="selectChange($event)"
  />

  <div class="go-back" (click)="onCloseCloningSection()"></div>
</div>

<locumsnest-slide-panel
  style="margin-right: -10px"
  [activePane]="generated() ? 'right' : 'left'"
  [alignCenter]="alignCenter()"
>
  <ngb-datepicker
    class="dates-container__datepicker"
    leftPane
    outsideDays="visible"
    navigation="arrows"
    [minDate]="minDate()"
    [displayMonths]="2"
    [dayTemplate]="t"
    (dateSelect)="onDateSelection($event)"
  />

  <ng-template #t let-date let-focused="focused" let-today="today">
    <span
      class="custom-day"
      [class.startDate]="
        date.equals(dateAdapter.fromModel(fromDate)) && !date.equals(dateAdapter.fromModel(toDate))
      "
      [class.endDate]="
        date.equals(dateAdapter.fromModel(toDate)) && !date.equals(dateAdapter.fromModel(fromDate))
      "
      [class.today]="today"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      [class.selected]="isSelected(date) && !isRange(date)"
      [class.listingRange]="isReferenceDate(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      <div>
        {{ date.day }} {{ isBankHoliday(date) ? '*' : '' }}
        @if (countDates()[dateAdapter.toModel(date)]; as count) {
          @if (count > 1) {
            <small class="custom-day__count">{{ count }}</small>
          }
        }
      </div>
    </span>
  </ng-template>

  <div class="dates-container__events" rightPane>
    @for (event of eventList(); track $index) {
      <locumsnest-tag-label class="dates-container__events__label" (remove)="removeDate($index)">
        {{ event.display }}
      </locumsnest-tag-label>

      @if (($index + 1) % 6 === 0) {
        <locumsnest-tag-label class="dates-container__events__label" style="visibility: hidden" />
      }
    }

    <locumsnest-tag-label
      class="dates-container__events__label"
      [isNew]="true"
      [isDisabled]="value().length >= maxAllowSelections()"
      (click)="addNew()"
    >
      add days
    </locumsnest-tag-label>
  </div>
</locumsnest-slide-panel>

<div class="footer-container">
  @if (bankHolidays().length) {
    <i class="footer-container__bank-holidays">* Bank holiday </i>
  }

  <span class="footer-container__events-count">
    {{ referenceStartDate() }}
  </span>
  <span class="footer-container__plus-icon">+</span>

  @if (tempValue().length) {
    <span class="footer-container__events-count">
      {{ tempValue().length | i18nPlural: shiftLabel }} generated
    </span>
    <span class="footer-container__plus-icon">+</span>
  }

  <span class="footer-container__events-count">
    {{ eventCount() | i18nPlural: shiftLabel }}
  </span>

  <div class="footer-container__buttons">
    @if (!generated()) {
      <locumsnest-button
        buttonType="accept"
        size="small"
        [hasBoldFont]="true"
        [disabled]="disableGenerateButton()"
        (click)="generate(true)"
      >
        GENERATE
      </locumsnest-button>
    }
    <locumsnest-button
      buttonType="alert"
      size="small"
      [hasBoldFont]="true"
      (click)="resetDatesAndRange(); resetError()"
    >
      RESET
    </locumsnest-button>
  </div>
</div>

<span class="error-message">
  {{ errorMsg() }}
</span>
