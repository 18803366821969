import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DocumentTypes,
  IPassportDocument,
  ProfileDocument,
} from 'apps/hospital-admin/src/app/profile/+state/interfaces';
import { IUpdateExpirationDate } from 'apps/hospital-admin/src/app/profile/+state/interfaces/update-expiration-date-payload';

import { Time } from '@locumsnest/core/src/lib/helpers';

import { StatusIndicatorComponent } from '../../atoms/status-indicator/status-indicator.component';
import { DatePickerComponent } from '../../molecules/date-picker/date-picker.component';
import { DialogComponent } from '../../molecules/dialog/dialog.component';
import { TextTruncatePipe } from '../../pipes/text-truncate/text-truncate.pipe';

@Component({
  selector: 'locumsnest-document-manager',
  templateUrl: './document-manager.component.html',
  styleUrls: ['./document-manager.component.scss'],
  standalone: true,
  imports: [
    StatusIndicatorComponent,
    DatePickerComponent,
    DialogComponent,
    TextTruncatePipe,
    CommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentManagerComponent {
  @Input() documentList: IPassportDocument[];
  @Input() allowFileCopy: boolean;
  @Input() showExpirationDate: boolean;
  @Input() canAddDocument: boolean;
  @Input() canChangeDocument: boolean;
  @Input() canDeleteDocument: boolean;

  @Output() confirmButtonClick = new EventEmitter<IUpdateExpirationDate>();
  @Output() resetButtonClick = new EventEmitter<IUpdateExpirationDate>();
  @Output() deleteButtonClick = new EventEmitter<ProfileDocument>();
  @Output() copyButtonClick = new EventEmitter<ProfileDocument>();

  public showModal = false;
  public selectedDoc = null;

  trackByDocument(_: number, doc: IPassportDocument) {
    return doc.id + doc.type;
  }

  isReference(doc: ProfileDocument) {
    return doc.type === DocumentTypes.reference;
  }

  isCertificate(doc: ProfileDocument) {
    return doc.type === DocumentTypes.certificate;
  }

  openModal(doc: ProfileDocument) {
    this.selectedDoc = doc;
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  public formatDate(date: Date) {
    if (date) {
      return Time.formatDate(date);
    }
  }

  public getStatusIndicator(date: Date) {
    if (!date) return 'ok';

    const differenceInDaysFromNow = Time.getDifferenceInDaysFromNow(date);
    if (differenceInDaysFromNow < 0) return 'alert';
    if (differenceInDaysFromNow > 30) return 'ok';
    if (differenceInDaysFromNow <= 30) return 'warning';
  }

  onConfirmButtonClick(expirationDate: Date, document: ProfileDocument): void {
    const updateExpirationDatePayload: IUpdateExpirationDate = {
      expirationDate,
      document,
    };
    this.confirmButtonClick.emit(updateExpirationDatePayload);
  }

  onResetButtonClick(document: ProfileDocument) {
    const updateExpirationDatePayload: IUpdateExpirationDate = {
      expirationDate: null,
      document,
    };
    this.resetButtonClick.emit(updateExpirationDatePayload);
  }

  onDeleteButtonClick(document: ProfileDocument): void {
    this.deleteButtonClick.emit(document);
  }

  onCopyButtonClick(document: ProfileDocument) {
    this.copyButtonClick.emit(document);
  }
}
