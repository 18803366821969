import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';

type IconType = 'approve' | 'reject' | 'calendar';

@Component({
  selector: 'locumsnest-icon-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() displayText: string;
  @Input() disabled: boolean;
  @Input() icon: IconType;
  @Output() buttonClicked = new EventEmitter<Date>();

  onButtonClicked() {
    if (!this.disabled) {
      this.buttonClicked.emit(Time.getDate());
    }
  }
}
