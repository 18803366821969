<label class="checkbox-box">
  <input
    name="radio"
    type="checkbox"
    #input
    [id]="idx()"
    [checked]="selected()"
    [disabled]="isDisabled()"
    (change)="onChecked($event)"
    [(ngModel)]="value"
  />
  <div class="custom-checkbox">
    @if (input.checked) {
      <locumsnest-icon class="custom-checkbox__icon" [autoSize]="true" [iconName]="iconName()" />
    }
  </div>

  {{ displayText() }}

  @if (errorMsg()) {
    <small class="text-danger" [id]="idx + '__error-message'">
      {{ errorMsg() }}
    </small>
  }
</label>
