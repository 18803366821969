import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  numberAttribute,
} from '@angular/core';

@Component({
  selector: 'locumsnest-icon',
  standalone: true,
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  iconName = input('lock');
  width = input(20, { transform: numberAttribute });
  height = input(20, { transform: numberAttribute });
  autoSize = input(false);

  iconPath = computed(() => `${this.iconBasePath}${this.iconName()}.svg#${this.iconName()}`);

  public readonly iconBasePath = '/assets/icons/';
}
