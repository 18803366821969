@if (showInline) {
  <div *ngFor="let item of list; let i = index; let last = last; trackBy: trackByFn" class="row">
    <div class="col-sm-11" style="display: flex">
      <ng-container *ngTemplateOutlet="item.content" />
      <div class="button button--add" [ngClass]="{ disabled: isDisabled }" (click)="onAdd()"></div>
      <div
        class="button button--delete"
        [ngClass]="{ disabled: isDisabled }"
        (click)="onRemove(item.id)"
      ></div>
    </div>
  </div>
} @else {
  <div class="container">
    <div *ngFor="let item of list; let i = index; let last = last; trackBy: trackByFn" class="row">
      <div class="col-sm-8 option">
        <div class="row">
          @if ((isRemovable || removalWhiteList.indexOf(item.id) > -1) && showDeleteButton) {
            <div
              class="trash-area"
              [ngClass]="{ disabled: isDisabled }"
              (click)="onRemove(item.id)"
            >
              <span
                class="trash-area__trash"
                [class.trash-area__trash--disabled]="
                  !isRemovable && !(removalWhiteList.indexOf(item.id) > -1)
                "
                >&nbsp;&nbsp;&nbsp;</span
              >
            </div>
          }
          <div class="col-sm-11">
            <ng-container *ngTemplateOutlet="item.content" />
          </div>
        </div>
      </div>
    </div>
    @if (!isReadOnly && showAddButton) {
      <div class="row button-add">
        <locumsnest-action-button
          [buttonType]="'button'"
          [style]="'Blue'"
          [disabled]="isDisabled"
          (click)="onAdd()"
        >
          <span class="button-text">
            {{ buttonText }}
          </span>
        </locumsnest-action-button>
      </div>
    }
  </div>
}
